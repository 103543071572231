body{
  margin:0;
  background-color: black;
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -38px;
}
.container:after {
    content: "";
    display: table;
    clear: both;
  }
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 px;
  }
  .column img {
    margin-top: 0px;
    vertical-align: middle;
    width: 100%;
  }
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .linkBox{
    width:100%;
    height:200px;
    margin-top:20px;
    text-align: center;
    color: aliceblue;
    justify-content: center;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.6278886554621849) 40%, rgba(0,212,255,1) 100%);
  }
  
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
}